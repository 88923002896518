<template>
  <router-link
    class="
      flex
      items-center
      justify-center
      w-full
      h-full
      border border-primary-500 border-dashed
      rounded
      min-h-[11rem]
    "
  >
    <slot />
  </router-link>
</template>
