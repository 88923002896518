import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative max-w-sm mx-auto my-5 p-5 bg-white rounded shadow-lg" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = { class: "space-x-1" }

import { ref, withDefaults, watch } from 'vue'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'
import { Btn } from '@/components'


export default _defineComponent({
  props: {
    show: { type: Boolean, required: true },
    title: { type: String, required: false, default: 'Confirmation' },
    confirmText: { type: String, required: false, default: 'Confirm' },
    cancelText: { type: String, required: false, default: 'Cancel' },
    confirmVariant: { type: String, required: false, default: 'primary' }
  } as unknown as undefined,
  emits: ["confirm", "cancel", "close"] as unknown as undefined,
  setup(__props: {
    show: boolean
    title?: string
    confirmText?: string
    cancelText?: string
    confirmVariant?: string
  }, { emit }: { emit: ({
  (e: 'confirm'): void
  (e: 'cancel'): void
  (e: 'close'): void
}), expose: any, slots: any, attrs: any }) {

const props = __props




const confirmButton = ref(null)
const isOpen = ref(false)

const setIsOpen = (value: boolean) => {
  isOpen.value = value
}

watch(
  () => props.show,
  (value) => (isOpen.value = !!value)
)

const close = () => {
  emit('close')
  setIsOpen(false)
}

const confirm = () => {
  emit('confirm')
  close()
}

const cancel = () => {
  emit('cancel')
  close()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    appear: "",
    show: isOpen.value,
    as: "template"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        open: isOpen.value,
        class: "fixed inset-0 z-30 overflow-y-auto",
        onClose: close
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TransitionChild), {
            enter: "duration-300 ease-out",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "duration-200 ease-in",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(DialogOverlay), { class: "fixed inset-0 bg-black/50" })
            ]),
            _: 1
          }),
          _createVNode(_unref(TransitionChild), {
            enter: "duration-300 ease-out",
            "enter-from": "opacity-0 scale-90",
            "enter-to": "opacity-100 scale-100",
            leave: "duration-200 ease-in",
            "leave-from": "opacity-100 scale-100",
            "leave-to": "opacity-0 scale-90"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("header", _hoisted_2, [
                  _createVNode(_unref(DialogTitle), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(__props.title), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _createElementVNode("footer", _hoisted_4, [
                  _createVNode(_unref(Btn), {
                    ref: confirmButton,
                    variant: __props.confirmVariant,
                    onClick: confirm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(__props.confirmText), 1)
                    ]),
                    _: 1
                  }, 8, ["variant"]),
                  _createVNode(_unref(Btn), {
                    outline: "",
                    onClick: cancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(__props.cancelText), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["open"])
    ]),
    _: 3
  }, 8, ["show"]))
}
}

})