import { reactive, readonly, inject, InjectionKey } from 'vue'
import { User } from '@/types'

export const StoreSymbol: InjectionKey<Store> = Symbol('STORE')

export interface State {
  scrollBody: boolean
  currentUser: User | null
}

export interface Actions {
  setScrollBody: (value: boolean) => void
  setCurrentUser: (value: User | null) => void
}

export interface Store {
  state: State
  actions: Actions
}

const state: State = reactive({
  scrollBody: false,
  currentUser: null,
})

const actions: Actions = {
  setScrollBody(value) {
    state.scrollBody = value
  },

  setCurrentUser(value) {
    state.currentUser = value
  },
}

export const store = {
  state: readonly(state),
  actions,
}

export const useStore = (): Store => {
  return inject(StoreSymbol) as Store
}
