import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { DotsVerticalIcon } from '@heroicons/vue/outline'


export default _defineComponent({
  props: {
    menuClass: { type: String, required: false }
  } as unknown as undefined,
  setup(__props: {
  menuClass?: string
}) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Menu), {
    as: "div",
    class: "relative w-8 h-8"
  }, {
    default: _withCtx((slotProps) => [
      _createVNode(_unref(MenuButton), {
        class: _normalizeClass(["\n        flex\n        items-center\n        justify-center\n        w-8\n        h-8\n        rounded\n        text-primary-500\n        hover:text-white hover:bg-primary-500\n        focus:outline-none focus:ring-4\n        ring-primary-500/50\n        group\n      ", {
        'bg-primary-500 !text-white': slotProps.open,
      }])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "icon", _normalizeProps(_guardReactiveProps(slotProps)), () => [
            _createVNode(_unref(DotsVerticalIcon), { class: "w-6 h-6" })
          ])
        ]),
        _: 2
      }, 1032, ["class"]),
      _createVNode(_unref(MenuItems), {
        class: _normalizeClass(["\n        absolute\n        flex flex-col\n        min-w-[200px]\n        border\n        rounded\n        bg-white\n        overflow-hidden\n        shadow-lg\n        focus:outline-none focus:ring-4\n        ring-primary-500/50 ring-offset-2\n      ", props.menuClass])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(slotProps)))
        ]),
        _: 2
      }, 1032, ["class"])
    ]),
    _: 3
  }))
}
}

})