import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-stretch gap-1" }
const _hoisted_2 = { class: "truncate" }

import { computed, withDefaults } from 'vue'
import { RouterLink, RouteLocationRaw } from 'vue-router'
import { DropdownMenu, MenuItemLink } from '@/components'
import { ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/vue/outline'

export interface BreadcrumbItem {
  text: string
  to: RouteLocationRaw
  replace?: boolean
}


export default _defineComponent({
  props: {
    items: { type: Array, required: true },
    reveal: { type: Number, required: false, default: 1 },
    descending: { type: Boolean, required: false, default: true }
  } as unknown as undefined,
  setup(__props: {
    items: BreadcrumbItem[]
    reveal?: number
    descending?: boolean
  }) {

const props = __props


const revealedItems = computed(() => props.items.slice(-Math.abs(props.reveal)))

const menuItems = computed(() =>
  props.descending ? [...props.items].reverse() : props.items
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(DropdownMenu), null, {
      icon: _withCtx(() => [
        _createVNode(_unref(ChevronDoubleLeftIcon), { class: "w-5 h-5" })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuItems), (item, i) => {
          return (_openBlock(), _createBlock(_unref(MenuItemLink), {
            key: i,
            to: item.to,
            class: "flex items-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(ChevronLeftIcon), { class: "w-5 h-5 mr-1" }),
              _createElementVNode("span", null, _toDisplayString(item.text), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(revealedItems), (item, i) => {
      return (_openBlock(), _createBlock(_unref(RouterLink), {
        key: i,
        to: item.to,
        replace: item.replace,
        class: "\n        flex\n        items-center\n        px-3\n        rounded\n        hover:bg-primary-500 hover:text-white\n        focus:outline-none focus:ring-4\n        ring-primary-500/50\n        hover:no-underline\n        overflow-hidden\n      "
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(ChevronLeftIcon), { class: "w-5 h-5 -ml-1 mr-1 flex-shrink-0" }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(item.text), 1)
        ]),
        _: 2
      }, 1032, ["to", "replace"]))
    }), 128))
  ]))
}
}

})