import { Attachment } from '@/types'
import {
  getDownloadURL,
  StorageReference,
  deleteObject,
} from 'firebase/storage'

export const attachmentFromRef = async (
  ref: StorageReference
): Promise<Attachment> => {
  return {
    ref,
    url: ref.fullPath ? await getDownloadURL(ref) : '',
  }
}

export const deleteAttachment = (attachment: Attachment): Promise<void> => {
  return deleteObject(attachment.ref)
}
