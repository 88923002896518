import { Container, DeleteItemOptions, Item, UpdateItemOptions } from '@/types'
import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  where,
  onSnapshot,
  query,
  DocumentData,
} from 'firebase/firestore'
import { getStorage, ref as fbRef } from 'firebase/storage'
import { db, auth } from '@/utils/firebase'
import { attachmentFromRef } from './attachments'
import isDefined from '@/utils/isDefined'

const storage = getStorage()

export const colRef = collection(db, 'items')

export const defaultItem = (parent?: Container): Item => ({
  id: '',
  name: '',
  parentId: parent?.id ?? '',
  coverImage: undefined,
  isDeleted: false,
  ancestors: [...(parent?.ancestors ?? []), ...(parent?.id ? [parent.id] : [])],
})

export const moveItem = async (
  item: Item,
  newParentId: string,
  newAncestors: string[],
  options?: UpdateItemOptions
): Promise<void> => {
  const change = {
    parentId: newParentId,
    ancestors: newAncestors,
  }

  if (options?.batch) {
    await options.batch.set(doc(colRef, item.id), change, { merge: true })
  } else {
    await setDoc(doc(colRef, item.id), change, { merge: true })
  }
}

export const deleteItem = async (
  item: Item,
  options?: DeleteItemOptions
): Promise<void> => {
  if (options?.batch) {
    await options.batch.set(
      doc(colRef, item.id),
      { isDeleted: true },
      { merge: true }
    )
  } else {
    await setDoc(doc(colRef, item.id), { isDeleted: true }, { merge: true })
  }
}

export const createItem = async (item: Item): Promise<string> => {
  const docRef = await addDoc(colRef, {
    name: item.name,
    users: {
      [auth.currentUser?.uid || '']: 'owner',
    },
    parentId: item.parentId,
    coverImagePath: item.coverImage?.ref.fullPath || '',
    isDeleted: false,
    ancestors: item.ancestors,
  })

  return docRef.id
}

export const updateItem = async (item: Item): Promise<void> => {
  return setDoc(
    doc(colRef, item.id),
    {
      name: item.name,
      coverImagePath: item.coverImage?.ref.fullPath || '',
    },
    { merge: true }
  )
}

export const getItem = async (id: string): Promise<Item | undefined> => {
  try {
    const snapshot = await getDoc(doc(colRef, id))
    const data = snapshot.data()

    if (!data) return

    return await docToItem(data, snapshot.id)
  } catch (err) {
    return
  }
}

export const listItems = async (userId: string): Promise<Item[]> => {
  const snapshot = await getDocs(
    query(
      colRef,
      where(`users.${userId}`, '==', 'owner'),
      where(`isDeleted`, '==', false)
    )
  )

  const items = await Promise.all(
    snapshot.docs.map((d) => docToItem(d.data(), d.id))
  )

  return items.filter(isDefined)
}

export const listItemsByParentId = async (
  userId: string,
  parentId: string
): Promise<Item[]> => {
  const snapshot = await getDocs(
    query(
      colRef,
      where(`users.${userId}`, '==', 'owner'),
      where('parentId', '==', parentId),
      where(`isDeleted`, '==', false)
    )
  )

  const items = await Promise.all(
    snapshot.docs.map((d) => docToItem(d.data(), d.id))
  )

  return items.filter(isDefined)
}

export const subscribeToItems = (
  userId: string,
  callback: (Items: Item[]) => void
): (() => void) => {
  const q = query(
    colRef,
    where(`users.${userId}`, '==', 'owner'),
    where(`isDeleted`, '==', false)
  )

  return onSnapshot(q, async (snapshot) => {
    const items = await Promise.all(
      snapshot.docs.map((doc) => docToItem(doc.data(), doc.id))
    )

    callback(items.filter(isDefined))
  })
}

export const subscribeToItemsByParentId = (
  userId: string,
  parentId: string,
  callback: (Items: Item[]) => void
): (() => void) => {
  const q = query(
    colRef,
    where(`users.${userId}`, '==', 'owner'),
    where('parentId', '==', parentId),
    where(`isDeleted`, '==', false)
  )

  return onSnapshot(q, async (snapshot) => {
    const items = await Promise.all(
      snapshot.docs.map((doc) => docToItem(doc.data(), doc.id))
    )

    callback(items.filter(isDefined))
  })
}

const docToItem = async (
  doc: DocumentData,
  id: string
): Promise<Item | undefined> => {
  if (doc.isDeleted) return undefined

  const imageRef = doc?.coverImagePath
    ? fbRef(storage, doc.coverImagePath)
    : null

  return {
    id,
    name: doc.name,
    parentId: doc.parentId,
    coverImage: imageRef ? await attachmentFromRef(imageRef) : null,
    isDeleted: doc.isDeleted,
    ancestors: doc.ancestors ?? [],
  } as Item
}
