import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"




export default _defineComponent({
  props: {
  variant: {
    type: String,
    default: 'info',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["px-5 py-3 border-l-4 rounded", {
      'border-primary-500 bg-primary-200 text-primary-900':
        __props.variant === 'primary',
      'border-secondary-500 bg-secondary-200 text-secondary-900':
        __props.variant === 'secondary',
      'border-danger-500 bg-danger-200 text-danger-900': __props.variant === 'danger',
      'border-warning-500 bg-warning-200 text-warning-900':
        __props.variant === 'warning',
      'border-success-500 bg-success-200 text-success-900':
        __props.variant === 'success',
      'border-info-500 bg-info-200 text-info-900': __props.variant === 'info',
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})