import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/index.css'
import './utils/firebase'
import fallbackSrc from './utils/fallbacksrc'
import { StoreSymbol, store } from './store'

// prettier-ignore
createApp(App)
  .use(router)
  .directive('fallback', fallbackSrc)
  .provide(StoreSymbol, store)
  .mount('#app')
