import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { MenuItem } from '@headlessui/vue'

export default _defineComponent({
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(MenuItem), {
    disabled: _ctx.$attrs.disabled
  }, {
    default: _withCtx((slotProps) => [
      _createVNode(_component_router_link, _mergeProps(_ctx.$attrs, {
        class: ["\n        px-3\n        py-2\n        hover:no-underline\n        whitespace-nowrap\n        text-black\n        cursor-pointer\n      ", {
        'bg-primary-500 !text-white': slotProps.active,
        '!text-gray-400': slotProps.disabled,
      }]
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(slotProps)))
        ]),
        _: 2
      }, 1040, ["class"])
    ]),
    _: 3
  }, 8, ["disabled"]))
}
}

})