import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "border rounded bg-white" }
const _hoisted_2 = { class: "\n        flex\n        content-center\n        justify-center\n        h-32\n        bg-gray-200\n        rounded-t\n        overflow-hidden\n      " }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "p-3 flex-grow truncate" }
const _hoisted_6 = {
  key: 0,
  class: "p-2"
}


import { DropdownMenu } from '../DropdownMenu'


export default _defineComponent({
  props: {
    mediaUrl: { type: String, required: false }
  } as unknown as undefined,
  setup(__props: {
  mediaUrl?: string
}) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "media", { mediaUrl: __props.mediaUrl }, () => [
        (__props.mediaUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: __props.mediaUrl,
              class: "w-full object-cover"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("figcaption", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.$slots.menu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_unref(DropdownMenu), { "menu-class": "right-0" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "menu")
              ]),
              _: 3
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})