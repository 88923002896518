import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot } from "vue"

import { withDefaults, ref, watch } from 'vue'
import {
  getStorage,
  ref as fbRef,
  uploadBytesResumable,
  UploadTask,
} from 'firebase/storage'
import slugify from 'slugify'
import { v4 as uuid } from 'uuid'

interface Progress {
  percent: number
  bytesSent: number
  bytesTotal: number
  filesSent: number
  filesTotal: number
}


export default _defineComponent({
  props: {
    files: { type: Array, required: true, default: () => [] },
    bucket: { type: String, required: false, default: 'attachments' },
    parentId: { type: String, required: false }
  } as unknown as undefined,
  emits: ["start", "sending", "complete", "progress", "error", "reset"] as unknown as undefined,
  setup(__props: {
    files: File[]
    bucket?: string
    parentId?: string
  }, { emit }: { emit: ({
  (e: 'start', uploadTasks: UploadTask[]): void
  (e: 'sending', sending: boolean): void
  (e: 'complete', uploadTasks: UploadTask[]): void
  (e: 'progress', progress: Progress): void
  (e: 'error', error: string): void
  (e: 'reset'): void
}), expose: any, slots: any, attrs: any }) {

const props = __props




const storage = getStorage()
const progress = ref<Progress>({
  percent: 0,
  bytesSent: 0,
  bytesTotal: 0,
  filesSent: 0,
  filesTotal: 0,
})
const error = ref('')
const sending = ref(false)
const complete = ref(false)

watch(
  () => props.files,
  () => {
    reset()
  }
)

let uploadTasks: UploadTask[] = []

const reset = () => {
  error.value = ''
  sending.value = false
  complete.value = false

  uploadTasks.map((task) => task.cancel())
  uploadTasks = []
  emit('reset')

  updateProgress()
  emit('progress', progress.value)
}

const getBytesSent = () =>
  uploadTasks.reduce((acc, curr) => {
    return acc + curr.snapshot.bytesTransferred
  }, 0)

const getBytesTotal = () =>
  props.files.reduce((acc, curr) => {
    return acc + curr.size
  }, 0)

const getFilesSent = () =>
  uploadTasks.reduce((acc, curr) => {
    return curr.snapshot.state === 'success' ? acc + 1 : acc
  }, 0)

const updateProgress = () => {
  const bytesSent = getBytesSent()
  const bytesTotal = getBytesTotal()
  const filesSent = getFilesSent()

  progress.value = {
    percent: bytesSent / bytesTotal || 0,
    bytesSent,
    bytesTotal,
    filesSent,
    filesTotal: props.files.length,
  }

  emit('progress', progress.value)
}

const upload = () => {
  reset()
  emit('start', uploadTasks)
  sending.value = true
  emit('sending', sending.value)

  uploadTasks = props.files.map((file) => {
    const fileParts = file.name.split('.')
    const ext = fileParts.pop()
    const fileName = `${uuid()}-${fileParts.join('')}`

    const path = [props.bucket, props.parentId, slugify(fileName)]
      .filter(Boolean)
      .join('/')

    const storageRef = fbRef(storage, `${path}.${ext}`)
    return uploadBytesResumable(storageRef, file, { contentType: file.type })
  })

  uploadTasks.map((task) => {
    const unsubscribe = task.on(
      'state_changed',
      () => {
        updateProgress()
      },
      (err) => {
        error.value = err.message
        emit('error', err.message)
        console.error(err)
      },
      async () => {
        unsubscribe()

        updateProgress()

        if (progress.value.filesSent === progress.value.filesTotal) {
          sending.value = false
          emit('sending', sending.value)
          complete.value = true
          emit('complete', uploadTasks)
        }
      }
    )
  })
}

return (_ctx: any,_cache: any) => {
  return _renderSlot(_ctx.$slots, "default", {
    upload: upload,
    reset: reset,
    sending: sending.value,
    progress: progress.value,
    error: error.value,
    complete: complete.value
  })
}
}

})