import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import 'vue-router' // needed for RouteMeta declaration
import { getCurrentUser } from '@/utils/firebase'

import Home from '@/views/Index.vue'

import Register from '@/views/Register.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import UsersIndex from '@/views/users/Index.vue'

import Upload from '@/views/Upload.vue'

import CollectionsLayout from '@/views/collections/Layout.vue'
import CollectionsIndex from '@/views/collections/Index.vue'
import CollectionsEdit from '@/views/collections/Edit.vue'
import CollectionsNew from '@/views/collections/New.vue'

import ItemsEdit from '@/views/items/Edit.vue'

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },

  {
    path: '/collections',
    component: CollectionsLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/collections',
        name: 'collections',
        component: CollectionsIndex,
      },
      {
        path: '/collections/new',
        name: 'collections-new',
        component: CollectionsNew,
      },
      {
        path: '/collections/:id',
        name: 'collections-edit',
        component: CollectionsEdit,
        props: true,
      },
      {
        path: '/collections/:id/new',
        name: 'collections-child-new',
        component: CollectionsNew,
        props: true,
      },
      {
        path: '/collections/:parentId/items/new',
        name: 'collections-items-new',
        component: ItemsEdit,
        props: true,
      },
      {
        path: '/collections/:parentId/items/:id',
        name: 'collections-items-edit',
        component: ItemsEdit,
        props: true,
      },
    ],
  },

  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    async beforeEnter() {
      const currentUser = await getCurrentUser()
      if (currentUser) {
        return { name: 'logout' }
      }
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },

  {
    path: '/users',
    name: 'users',
    component: UsersIndex,
    meta: { requiresAuth: true },
  },

  {
    path: '/upload',
    name: 'upload',
    component: Upload,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // do not scroll when updating route for modals
    if ('modal' in to.query || 'modal' in from.query) return

    return savedPosition ?? { top: 0 }
  },
  routes,
})

router.beforeEach(async (to) => {
  const currentUser = await getCurrentUser()
  if (to.meta.requiresAuth && !currentUser) {
    return {
      name: 'login',
      query: { redirect: to.fullPath },
    }
  }
})

export default router
