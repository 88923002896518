import {
  collection,
  query,
  getDocs,
  DocumentData,
  setDoc,
  doc,
} from 'firebase/firestore'
import { db } from '@/utils/firebase'
import { User } from '@/types'

export const colRef = collection(db, 'users')

export const listUsers = async (): Promise<User[]> => {
  const q = query(collection(db, 'users'))
  const snapshot = await getDocs(q)
  return snapshot.docs.map((doc) => docToUser(doc.data(), doc.id))
}

// https://firebase.google.com/docs/firestore/query-data/listen#web-v9
// export const subscribeToUsers = (
//   callback: (users: User[]) => void
// ): (() => void) => {

//   return firebase
//     .firestore()
//     .collection('containers')
//     .where(`users.${userId}`, '==', 'owner')
//     .onSnapshot((snapshot) => {
//       callback(snapshot.docs.map((doc) => docToContainer(doc.data(), doc.id)))
//     })
// }

export const updateUser = async (user: User): Promise<void> => {
  return setDoc(
    doc(colRef, user.id),
    {
      email: user.email,
    },
    { merge: true }
  )
}

const docToUser = (doc: DocumentData | undefined, id: string): User => {
  return {
    id: id || '',
    email: doc?.email || '',
  }
}
