import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


// @ts-ignore
import { RouterLink } from 'vue-router'


export default _defineComponent({
  props: {
  variant: {
    type: String,
    default: 'secondary',
  },

  size: {
    type: String,
    default: 'md',
  },

  outline: {
    type: Boolean,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$attrs.to ? 'RouterLink' : _ctx.$attrs.href ? 'a' : 'button'), _mergeProps(_ctx.$attrs, {
    to: _ctx.$attrs.to,
    class: ["\n      inline-block\n      rounded\n      focus:outline-none focus:ring\n      hover:no-underline\n      transition-colors\n      duration-100\n      border\n      text-center\n    ", {
      'px-2 py-1 text-sm': __props.size === 'sm',
      'px-3 py-2 text-md': __props.size === 'md',
      'px-4 py-3 text-lg': __props.size === 'lg',

      'focus:ring-secondary-500 focus:ring-opacity-40':
        !_ctx.$attrs.disabled && __props.variant === 'secondary',
      'focus:ring-primary-500 focus:ring-opacity-50':
        !_ctx.$attrs.disabled && __props.variant === 'primary',
      'focus:ring-danger-500 focus:ring-opacity-50':
        !_ctx.$attrs.disabled && __props.variant === 'danger',
      'focus:ring-warning-500 focus:ring-opacity-50':
        !_ctx.$attrs.disabled && __props.variant === 'warning',
      'focus:ring-success-500 focus:ring-opacity-50':
        !_ctx.$attrs.disabled && __props.variant === 'success',
      'focus:ring-info-400 focus:ring-opacity-50':
        !_ctx.$attrs.disabled && __props.variant === 'info',

      'bg-secondary-400 hover:bg-secondary-500 text-white':
        !_ctx.$attrs.disabled && !__props.outline && __props.variant === 'secondary',
      'bg-primary-500 hover:bg-primary-600 text-white':
        !_ctx.$attrs.disabled && !__props.outline && __props.variant === 'primary',
      'bg-danger-500 hover:bg-danger-600 text-white':
        !_ctx.$attrs.disabled && !__props.outline && __props.variant === 'danger',
      'bg-warning-500 hover:bg-warning-600 text-white':
        !_ctx.$attrs.disabled && !__props.outline && __props.variant === 'warning',
      'bg-success-500 hover:bg-success-600 text-white':
        !_ctx.$attrs.disabled && !__props.outline && __props.variant === 'success',
      'bg-info-400 hover:bg-info-500 text-white':
        !_ctx.$attrs.disabled && !__props.outline && __props.variant === 'info',

      'border-transparent': !__props.outline,

      'border-secondary-400 hover:border-transparent hover:bg-secondary-400 text-secondary-500 hover:text-white':
        !_ctx.$attrs.disabled && __props.outline && __props.variant === 'secondary',
      'border-primary-500 hover:border-transparent hover:bg-primary-500 text-primary-600 hover:text-white':
        !_ctx.$attrs.disabled && __props.outline && __props.variant === 'primary',
      'border-danger-500 hover:border-transparent hover:bg-danger-500 text-danger-600 hover:text-white':
        !_ctx.$attrs.disabled && __props.outline && __props.variant === 'danger',
      'border-warning-500 hover:border-transparent hover:bg-warning-500 text-warning-600 hover:text-white':
        !_ctx.$attrs.disabled && __props.outline && __props.variant === 'warning',
      'border-success-500 hover:border-transparent hover:bg-success-500 text-success-600 hover:text-white':
        !_ctx.$attrs.disabled && __props.outline && __props.variant === 'success',
      'border-info-400 hover:border-transparent hover:bg-info-400 text-info-600 hover:text-white':
        !_ctx.$attrs.disabled && __props.outline && __props.variant === 'info',

      'pointer-events-none': _ctx.$attrs.disabled,
      'bg-disabled-300 text-disabled-600 cursor-not-allowed': _ctx.$attrs.disabled,
    }]
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["to", "class"]))
}
}

})