export default {
  mounted(
    el: HTMLImageElement,
    { value: fallbackUrl }: { value: string }
  ): void {
    // TODO: configurable default fallback
    try {
      const originalUrl = el.src

      const img = new Image()
      img.src = originalUrl

      img.onload = () => {
        el.src = originalUrl

        img.onload = null
        img.onerror = null
      }

      img.onerror = () => {
        console.log('fallback', fallbackUrl)
        el.src = fallbackUrl

        img.onload = null
        img.onerror = null
      }
    } catch (err) {
      console.error(err)
    }
  },
}
