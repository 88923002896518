<template>
  <div
    class="inline-block w-full bg-gray-300 rounded h-[1em]"
    :class="animationClass"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    animationClass: {
      type: [String, Array, Object],
      default: 'animate-pulse',
    },
  },
})
</script>
