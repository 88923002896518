
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    shape: {
      type: String,
      default: 'rect', // rect, square, circle
    },

    flat: {
      type: Boolean,
    },

    animationClass: {
      type: [String, Array, Object],
      default: 'animate-pulse',
    },
  },
})
