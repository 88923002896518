<template>
  <div
    class="w-full bg-gray-300"
    :class="[
      animationClass,
      {
        rounded: shape !== 'circle' && !flat,
        'h-20': shape === 'rect',
        'square rounded-full': shape === 'circle',
        square: shape === 'square',
      },
    ]"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    shape: {
      type: String,
      default: 'rect', // rect, square, circle
    },

    flat: {
      type: Boolean,
    },

    animationClass: {
      type: [String, Array, Object],
      default: 'animate-pulse',
    },
  },
})
</script>

<style scoped>
.square {
  position: relative;
}

.square::before {
  content: '';
  display: block;
  padding-top: 100%;
}
</style>
