import {
  Container,
  DeleteContainerOptions,
  UpdateContainerOptions,
} from '@/types'
import {
  doc,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  where,
  onSnapshot,
  query,
  writeBatch,
  DocumentData,
  QueryConstraint,
  documentId,
} from 'firebase/firestore'
import { getStorage, ref as fbRef } from 'firebase/storage'
import { db, auth } from '@/utils/firebase'
import { attachmentFromRef } from './attachments'
import isDefined from '@/utils/isDefined'
import { deleteItem, listItemsByParentId, moveItem } from './items'

const storage = getStorage()

export const colRef = collection(db, 'containers')

export const defaultContainer = (parent?: Container): Container => ({
  id: '',
  name: '',
  parentId: parent?.id ?? '',
  coverImage: undefined,
  isDeleted: false,
  ancestors: [...(parent?.ancestors ?? []), ...(parent?.id ? [parent.id] : [])],
})

export const createContainer = async (
  container: Container
): Promise<string> => {
  const docRef = await addDoc(colRef, {
    name: container.name,
    users: {
      [auth.currentUser?.uid || '']: 'owner',
    },
    parentId: container.parentId,
    coverImagePath: container.coverImage?.ref.fullPath || '',
    isDeleted: false,
    ancestors: container.ancestors,
  })
  return docRef.id
}

export const updateContainer = async (container: Container): Promise<void> => {
  return setDoc(
    doc(colRef, container.id),
    {
      name: container.name,
      coverImagePath: container.coverImage?.ref.fullPath || '',
    },
    { merge: true }
  )
}

export const moveContainer = async (
  userId: string,
  container: Container,
  newParentId: string,
  newAncestors: string[],
  options?: UpdateContainerOptions
): Promise<void> => {
  if (
    newAncestors.length &&
    newAncestors[newAncestors.length - 1] !== newParentId
  )
    throw Error('Cannot move container due to invalid ancestry')

  const batch = options?.batch ?? writeBatch(db)

  await batch.set(
    doc(colRef, container.id),
    {
      parentId: newParentId,
      ancestors: newAncestors,
    },
    { merge: true }
  )

  const children = await listContainersByParentId(userId, container.id)
  const items = await listItemsByParentId(userId, container.id)

  const updateContainerPromises = children.map((c) =>
    moveContainer(userId, c, container.id, [...newAncestors, container.id], {
      batch,
    })
  )

  const updateItemPromises = items.map((i) =>
    moveItem(i, container.id, [...newAncestors, container.id], { batch })
  )

  await Promise.all([...updateContainerPromises, ...updateItemPromises])

  // Back at top level, commit batch writes
  if (!options?.batch) {
    await batch.commit()
  }
}

export const deleteContainer = async (
  userId: string,
  container: Container,
  options?: DeleteContainerOptions
): Promise<void> => {
  const batch = options?.batch ?? writeBatch(db)
  batch.set(doc(colRef, container.id), { isDeleted: true }, { merge: true })

  const children = await listContainersByParentId(userId, container.id)
  const items = await listItemsByParentId(userId, container.id)

  if (options?.moveContentsTo !== undefined) {
    let newParentId: string
    let newAncestors: string[]

    if (options.moveContentsTo === '') {
      newParentId = ''
      newAncestors = []
    } else {
      const moveTarget = await getContainer(options.moveContentsTo)
      if (!moveTarget)
        throw Error('Cannot move container to non-existent parent')
      newParentId = moveTarget.id
      newAncestors = [...moveTarget.ancestors, moveTarget.id]
    }

    const updateContainerPromises = children.map((c) =>
      moveContainer(userId, c, newParentId, newAncestors, { batch })
    )

    const updateItemPromises = items.map((i) =>
      moveItem(i, newParentId, newAncestors, { batch })
    )

    await Promise.all([...updateContainerPromises, ...updateItemPromises])
  } else if (options?.deleteContents) {
    const deleteContainerPromises = children.map((c) =>
      deleteContainer(userId, c, { ...options, batch })
    )

    const deleteItemPromises = items.map((i) => deleteItem(i, { batch }))

    await Promise.all([...deleteContainerPromises, ...deleteItemPromises])
  }

  // Back at top level, commit batch writes
  if (!options?.batch) {
    await batch.commit()
  }
}

export const getContainer = async (
  id: string
): Promise<Container | undefined> => {
  try {
    const snapshot = await getDoc(doc(colRef, id))
    const data = snapshot.data()

    if (!data) return

    return await docToContainer(data, snapshot.id)
  } catch (err) {
    return
  }
}

export const getContainersById = async (
  userId: string,
  ids: string[]
): Promise<Container[]> => {
  if (ids.length == 0) return []
  if (ids.length > 10)
    throw Error('Cannot retrieve more than 10 containers at a time') // Limited by the `in` query

  const containers = await listContainers(userId, [
    where(documentId(), 'in', ids),
  ])

  return containers
}

export const listContainers = async (
  userId: string,
  queries: QueryConstraint[] = []
): Promise<Container[]> => {
  const snapshot = await getDocs(
    query(
      colRef,
      where(`users.${userId}`, '==', 'owner'),
      where('isDeleted', '==', false),
      ...queries
    )
  )

  const containers = await Promise.all(
    snapshot.docs.map((d) => docToContainer(d.data(), d.id))
  )

  return containers.filter(isDefined)
}

export const subscribeToContainers = (
  userId: string,
  callback: (containers: Container[]) => void
): (() => void) => {
  const q = query(
    colRef,
    where(`users.${userId}`, '==', 'owner'),
    where('isDeleted', '==', false)
  )

  return onSnapshot(q, async (snapshot) => {
    const containers = await Promise.all(
      snapshot.docs.map((doc) => docToContainer(doc.data(), doc.id))
    )
    callback(containers.filter(isDefined))
  })
}

export const listContainersByParentId = async (
  userId: string,
  parentId: string
): Promise<Container[]> => {
  const snapshot = await getDocs(
    query(
      colRef,
      where(`users.${userId}`, '==', 'owner'),
      where('parentId', '==', parentId),
      where('isDeleted', '==', false)
    )
  )

  const containers = await Promise.all(
    snapshot.docs.map((d) => docToContainer(d.data(), d.id))
  )

  return containers.filter(isDefined)
}

export const subscribeToContainersByParentId = (
  userId: string,
  parentId: string,
  callback: (containers: Container[]) => void
): (() => void) => {
  const q = query(
    colRef,
    where(`users.${userId}`, '==', 'owner'),
    where('parentId', '==', parentId),
    where('isDeleted', '==', false)
  )

  return onSnapshot(q, async (snapshot) => {
    const containers = await Promise.all(
      snapshot.docs.map((doc) => docToContainer(doc.data(), doc.id))
    )

    const validContainers = containers.filter(isDefined)

    callback(validContainers)
  })
}

const docToContainer = async (
  doc: DocumentData,
  id: string
): Promise<Container | undefined> => {
  if (doc.isDeleted) return undefined

  const imageRef = doc?.coverImagePath
    ? fbRef(storage, doc.coverImagePath)
    : null

  return {
    id,
    name: doc.name,
    parentId: doc.parentId,
    coverImage: imageRef ? await attachmentFromRef(imageRef) : null,
    isDeleted: doc.isDeleted,
    ancestors: doc.ancestors ?? [],
  } as Container
}
