<template>
  <slot :index="i - 1" v-for="i in repeatCount" :key="i" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    repeat: {
      type: [Number, String],
      default: 1,
    },
  },

  setup(props) {
    const repeatCount = computed(() => parseInt(props.repeat.toString()))

    return {
      repeatCount,
    }
  },
})
</script>
