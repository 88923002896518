import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-3 rounded-full bg-gray-300 overflow-hidden" }

import { withDefaults } from 'vue'


export default _defineComponent({
  props: {
    percent: { type: Number, required: false, default: 0 }
  } as unknown as undefined,
  setup(__props: {
    percent?: number
  }) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "\n        h-full\n        rounded-full\n        bg-primary-500\n        transition-all\n        duration-100\n        ease-linear\n      ",
      style: _normalizeStyle({ width: `${__props.percent * 100}%` })
    }, null, 4)
  ]))
}
}

})