
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    animationClass: {
      type: [String, Array, Object],
      default: 'animate-pulse',
    },
  },
})
