import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { MenuItem } from '@headlessui/vue'

export default _defineComponent({
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(MenuItem), {
    disabled: _ctx.$attrs.disabled
  }, {
    default: _withCtx((slotProps) => [
      _createElementVNode("button", {
        class: _normalizeClass(["px-3 py-2 hover:no-underline whitespace-nowrap text-left", {
        'bg-primary-500 text-white': slotProps.active,
        'text-gray-400': slotProps.disabled,
      }])
      }, [
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(slotProps)))
      ], 2)
    ]),
    _: 3
  }, 8, ["disabled"]))
}
}

})